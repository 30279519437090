import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import history from '../../history';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    // borderRadius: 20,
    // borderColor: theme.palette.primary.main,
    // backgroundColor: fade(theme.palette.common.black, 0.05),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.black, 0.1),
    // },
    borderRadius: 20,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '50ch',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(3),
    //   width: 'auto',
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
      // width: '12ch',
      // '&:focus': {
      //   width: '50ch',
      // },
    },
  },
}));

const STOCK_INFO_QUERY = gql`
  query getStockInfo {
    stock {
      code
      chineseName
      englishName
    }
  }
`;

const StockSearchBar = () => {
  const classes = useStyles();

  let [searchKey, setSearchKey] = React.useState('');

  let options = [];
  const { loading, error, data } = useQuery(STOCK_INFO_QUERY);
  // downloaded data
  if (!loading && !error && data) {
    options = data.stock;
  }

  const onChange = (event, newValue) => {
    if (newValue && newValue.code) {
      setSearchKey('');
      event.preventDefault();
      history.push(`/stock/${newValue.code}`);
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option ==='string') {
      return option;
    }
    if (option.code) {
      return `${option.code} ${option.chineseName} ${option.englishName}`;
    }
    return "";
  };

  return (
    <Autocomplete
      id="combo-stock-search-bar"
      value={searchKey}
      options={options}
      getOptionLabel={getOptionLabel}
      style={{ width: '50ch' }}
      // classes={{
      //   root: classes.inputRoot,
      //   input: classes.inputInput,
      // }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.search}
          {...params}
          label="Stock"
          margin="dense"
          variant="outlined"
        />
      )}
    />
  );
};

// StockSearchBar.propTypes = {
//   // eslint-disable-next-line react/no-unused-prop-types
//   searchKey: PropTypes.string,
//   // eslint-disable-next-line react/no-unused-prop-types
//   dataSource: PropTypes.string,
// };

// StockSearchBar.defaultProps = {
//   searchKey: '',
//   dataSource: '',
// };

export default StockSearchBar;
